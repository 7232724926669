import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Tweet from "../../components/Tweet";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (2/23/2020): In addition to the issue this post originally described, I've reported another, similar issue for SwiftUI in AppKit. See the bottom of this post for more info.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (11/14/2020): I've added some more symbols I've seen in crashes with similar causes, which hopefully will be indexed by Google and help developers facing similar issues. My go-to strategy for fixing internal SwiftUI crashes (especially on macOS) has been "Is something not Equatable? Make it Equatable."`}</p>
    </blockquote>
    <p>{`I've been working lately on rewriting some of `}<a parentName="p" {...{
        "href": "/blog/cifilterio"
      }}>{`CIFilter.io`}</a>{` in SwiftUI. One crash I came across that initially stumped me would happen when resetting my `}<inlineCode parentName="p">{`UIHostingController`}</inlineCode>{`'s `}<inlineCode parentName="p">{`rootView`}</inlineCode>{` to a view that was equivalent to the one already set (mostly, this happened when transitioning the window from full to split-screen mode). The crash only had one stackframe that started with `}<inlineCode parentName="p">{`AG::LayoutDescriptor::compare`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`#0	0x00007fff4a9b6069 in AG::LayoutDescriptor::compare(unsigned char const*, unsigned char const*, unsigned char const*, unsigned long, unsigned int) ()`}</span></code></pre>
    <p>{`Initially I had no idea what was going on here, but I found two clues. One was this tweet:`}</p>
    <Tweet tweetId="1268846742558179330" mdxType="Tweet" />
    <p>{`Looks like `}<a parentName="p" {...{
        "href": "https://medium.com/@DAloG"
      }}>{`Alexey Demedeckiy`}</a>{` ran into this a while back, and the solution was to make an enum with many cases conform to `}<inlineCode parentName="p">{`Equatable`}</inlineCode>{`. I realized that I also had an enum (`}<inlineCode parentName="p">{`FilterParameterType`}</inlineCode>{`) with 20+ cases which I was now using as part of the SwiftUI view state.`}</p>
    <p>{`The second clue was that the crashing method was called `}<inlineCode parentName="p">{`compare`}</inlineCode>{`: my enum wasn't equatable, but it seems like SwiftUI was doing something under the hood to try to compare it anyways.`}</p>
    <p>{`I spent a bit of time creating a sample project and whitling down my code into a minimal reproduction case, and I realized that the following triggers the same crash:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`enum`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`one`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`two`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`three`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`four`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`five`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`six`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`seven`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`eight`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`nine`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ten`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@State`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` type: Type = .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`one`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"one"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`VStack`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alignment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leading`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Button`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`action`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`one`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`info`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"one"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`label`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Tap here to crash"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Tapping that button (twice, for some reason?) crashes the app!`}</p>
    <p>{`This was a super, super interesting bug to find. Even more interesting is that the crash `}<em parentName="p">{`doesn't`}</em>{` happen if you do any of the following:`}</p>
    <ol>
      <li parentName="ol">{`Make the enum `}<inlineCode parentName="li">{`Equatable`}</inlineCode></li>
      <li parentName="ol">{`Comment out one of the cases`}</li>
      <li parentName="ol">{`Comment out the `}<em parentName="li">{`associated value`}</em>{` of one of the cases`}</li>
    </ol>
    <p>{`From what I can tell, SwiftUI's trying hard to compare the enum, even if it's not equatable, but I guess 10 cases is the point at which it gives up.`}</p>
    <p>{`The takeaway: if you use enums as part of your SwiftUI view state, make them Equatable.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A test project which reproduces this issue is available at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSwiftUIEquatableCrashExample"
        }}>{`NGSwiftUIEquatableCrashExample`}</a>{`. I've filed this issue with Apple as FB7847416.`}</p>
    </blockquote>
    <h2 {...{
      "id": "bonus"
    }}>{`Bonus`}</h2>
    <p>{`About a month after this issue, I noticed another, similar issue. It seems a similar crash can happen in SwiftUI running on top of AppKit (in macOS apps) with the following code:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`NormalExperienceState`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` one: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` two: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` three: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` four: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` five: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` six: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` seven: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` eight: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` nine: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ten: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` eleven: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` twelve: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` thirteen: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@State`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` state = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`NormalExperienceState`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Hello, world!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`The crash seems to happen most frequently in `}<inlineCode parentName="p">{`objc_release`}</inlineCode>{`, `}<inlineCode parentName="p">{`StateStorageBox`}</inlineCode>{`, `}<inlineCode parentName="p">{`swift_initStructMetadata`}</inlineCode>{`, or `}<inlineCode parentName="p">{`outlined copy of`}</inlineCode>{` various SwiftUI structs. (Putting these keywords here in case someone is googling and needs to find them.) Similarly, making the struct conform correctly to `}<inlineCode parentName="p">{`Equatable`}</inlineCode>{` solves the problem.`}</p>
    <p>{`I've created `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSwiftUIEquatableStateIssue"
      }}>{`another sample project`}</a>{` (with linked feedback number) for this.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      